@import url("https://fonts.googleapis.com/css?family=Open+Sans");
html,
body {
  margin: 0;
  background-color: #f9f9f9;
}

* {
  color: #31394f;
  font-family: "Open Sans", sans-serif;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.main-wrapper {
  width: 720px;
}
.box {
  padding: 32px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.box > .title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 6px;
  margin-bottom: 16px;
}

.timeslots {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.timeslots.columns {
  flex-direction: row;
  gap: 16px;
}

.timeslot {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.timeslot span {
  font-weight: bold;
}

.timeslot .status {
  border: 1px solid #a8afb0;
  border-radius: 6px;
  padding: 10px 6px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  color: #31394f;
}
.timeslot .status .free {
}

.timeslot.checked .status {
  background-color: #34a853;
  border-color: #34a853;
  color: #fff;
}

.timeslot .status.booked {
  background-color: #ea4335;
  border-color: #ea4335;
  color: #fff;
}

.timeslot.unavailable > *,
.timeslots.view-mode .timeslot:not(.checked) {
  opacity: 0.4;
}
.timeslot.unavailable > *,
.timeslots.view-mode .timeslot .status {
  cursor: auto;
  user-select: none;
}

.timeslots:not(.view-mode) .timeslot:not(.unavailable) .status:hover {
  background-color: #31394f;
  border-color: #31394f;
  color: #fff;
}

button.button,
a.button {
  border: 1px solid #a8afb0;
  padding: 0 28px;
  height: 50px;

  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 8px;

  color: #31394f;
  font-size: 14px;
  text-decoration: none;
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

button.button:hover,
a.button:hover,
button.button:hover .icon,
a.button:hover .icon {
  background-color: #31394f;
  color: #fff;
  border-color: #31394f;
  fill: #fff;
}
button.button .icon,
a.button .icon {
  width: 24px;
  height: 24px;
}
button.button:active {
  transform: translateY(1px);
}

.button-group {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.textWhite {
  color: #fff;
}

.textDark {
  color: #393f4f;
}

.bgRed {
  background-color: #f25d5d;
}

.bgBlue {
  background-color: #0880ae;
}

.bgOrange {
  background-color: #f38637;
}

.bgBlack {
  background-color: #000;
}

.bgGreen {
  background-color: #4d9c50;
}

input[type="text"] {
  border: 0;
  padding: 0;
  margin: 0;
  border: 1px solid #a8afb0;
  border-radius: 6px;
  padding: 0 16px;
  height: 50px;
  box-sizing: border-box;
  flex-grow: 1;
}

input[type="text"]::selection {
  background-color: #000;
  color: #fff;
}

input[type="text"]:read-only {
  background-color: #f3f3f3;
}
input[type="text"]:focus {
  outline: none;
}
.input-row {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 8px 0;
  flex-wrap: wrap;
  width: 100%;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin: 0;
}

.box .box-section {
  margin-bottom: 32px;
}

.box .box-section > p {
  margin-top: 0;
}
.box .box-section .title {
  font-weight: bold;
  font-size: 18px;
}

.full-flex {
  flex: 1;
}

.timeslots .timeslots-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

.timeslots .timeslots-column.author {
  flex-grow: 0;
}

.calendar {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.calendar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.calendar ul.week-row {
  display: flex;
  gap: 4px;
}
.calendar ul.week-row li {
  display: flex;
  border-radius: 6px;
  border: 1px solid #a8afb0;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendar ul.week-row li:hover {
  background-color: #31394f;
  color: #fff;
  border-color: #31394f;
}

.flexRight {
  justify-content: flex-end;
}

.alert {
  display: block;
  padding: 12px 16px;
  font-size: 1em;
  border-radius: 4px;
  color: #000;
  border: 1px solid #fbbc04;
  background: #fff9e0;
}

.alert.error {
  background-color: #fac0bb;
  border-color: #ea4335;
}
